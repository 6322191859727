.unit-pricelist-v1 {
	.accord-head {


		a {
			position: relative;

			&:after {
				content: "\f107";
				font-family: Font Awesome\ 5 Free;
				font-weight: 900;
				position: absolute;
				right: 16px;
				top: 20px;
				font-size: 25px;
				transform: rotate(0deg);
				line-height: 1;
				transition: all .2s ease-in-out;

			}

			&[aria-expanded=true] {
				&:after {
					transform: rotate(-180deg) !important;
				}

			}


		}


	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}